<template>
  <v-container fluid>
    <!-- Capture dialog -->
    <v-dialog v-model="showDialog" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialog = false"> Fechar </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Capturar</span>
        </v-card-title>

        <v-card-text class="">
          Confirmar captura do processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doCapture(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="red" color="white" text @click="showDialog = false">
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogEmail" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogEmail = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline"
            >Processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}</span
          >
        </v-card-title>

        <v-card-text class=""> Confirma que email foi enviado </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doEmailSent(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogEmail = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogEdit" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogEdit = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Editar nota</span>
        </v-card-title>

        <v-card-text class="">
          <v-currency-field
            label="Valor"
            prefix="R$"
            class="mx-2"
            style="font-size: 14px"
            filled
            rounded
            dense
            solo
            v-model="itemSelected[`VL_NOTA_${tipoNota}`]"
          />
        </v-card-text>

        <v-card-text class="">
          Confirmar edição do processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="green dark"
            color="white"
            text
            @click="doEdit(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red dark"
            color="white"
            text
            @click="showDialogEdit = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- finish -->
    <v-dialog v-model="showDialogFinish" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogFinish = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Finalizar etapa</span>
        </v-card-title>
        <v-card-text class="">
          Confirmar fechamento da etapa do processo
          {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doFinish(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogFinish = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Obs -->
    <v-dialog v-model="showDialogObs" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogObs = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline"
            >Observações - Processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}</span
          >
        </v-card-title>
        <!-- <v-card-text class="">
          Processo
          {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text> -->

        <v-card-text class="">
          <v-textarea
            v-model="itemSelected.OBS"
            :disabled="
              (slug !== 'fechados' &&
                itemSelected[dateCaptureField] &&
                !itemSelected[tab.dateClose] &&
                !itemSelected[tab.dateEnd]) ||
              (slug == 'operacional' && !itemSelected.EMAIL_SENT)
                ? false
                : true
            "
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions
          v-if="
            (slug !== 'fechados' &&
              itemSelected[dateCaptureField] &&
              !itemSelected[tab.dateClose] &&
              !itemSelected[tab.dateEnd]) ||
            (slug == 'operacional' && !itemSelected.EMAIL_SENT)
          "
        >
          <v-btn
            class="green"
            color="white"
            text
            @click="doEditRaw(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogFinish = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- User history -->
    <v-dialog v-model="showDialogUserLogs" width="800px">
      <v-card fluid>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogUserLogs = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Usuários responsáveis</span>
        </v-card-title>

        <v-card-text class="">
          <v-simple-table>
            <thead>
              <tr>
                <th>Atividade</th>
                <th class="subheading font-weight-light text-center">
                  Usuário
                </th>
                <th class="subheading font-weight-light text-center">
                  Data captura
                </th>
                <th class="subheading font-weight-light text-center">
                  Data finalização
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr v-for="(userLogs, index) in userLogs" :key="index">
                <th class="text-left font-weight-light subtitle-1">
                  {{ userLogs.label }}
                </th>
                <th class="text-left font-weight-light subtitle-1">
                  {{ itemSelected[userLogs.userField]?.username ?? "" }}
                </th>

                <th class="text-left font-weight-light subtitle-1">
                  <!-- {{ itemSelected[userLogs.userField]?.username ?? "" }} -->
                  <span
                    v-date-format="itemSelected[userLogs.dateCaptureField]"
                  ></span>
                </th>

                <th class="text-left font-weight-light subtitle-1">
                  <!-- {{ itemSelected[userLogs.userField]?.username ?? "" }} -->
                  <span
                    v-date-format="itemSelected[userLogs.dateCloseField]"
                  ></span>
                </th>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- report -->
    <v-dialog v-model="showDialogReport" fluid>
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogReport = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Relatório de despesas</span>
        </v-card-title>

        <v-card-text>
          <TabelaProcessosTotais
            :process="itemSelected"
            :last-refresh="lastRefreshExpenses"
          ></TabelaProcessosTotais>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="custom-table"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length.sync="serverItemsLength"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      @update:items-per-page="updateRowsPerPage"
      @update:page="updatePage"
      ref="dataTable"
    >
      <template v-slot:item.DTA_EMISS_1SAIDA="{ item }">
        <span v-date-format="item.DTA_EMISS_1SAIDA"></span>
      </template>
      <template v-slot:item.DTA_CAPTURA_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_CAPTURA_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_FECH_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_FECH_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_FIM_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_FIM_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_FIM_FATURAMENTO="{ item }">
        <span v-date-format="item.DTA_FIM_FATURAMENTO"></span>
      </template>

      <template v-slot:item.DTA_FIM_CONTABIL="{ item }">
        <span v-date-format="item.DTA_FIM_CONTABIL"></span>
      </template>

      <template v-slot:item.DTA_FIM_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_FIM_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_CAPTURA_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_CAPTURA_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_FECH_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_FECH_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_CAPTURA_CONTABIL="{ item }">
        <span v-date-format="item.DTA_CAPTURA_CONTABIL"></span>
      </template>

      <template v-slot:item.DTA_FECH_CONTABIL="{ item }">
        <span v-date-format="item.DTA_FECH_CONTABIL"></span>
      </template>

      <template v-slot:item.USER_CAPTURA_OPERACIONAL="{ item }">
        <span>{{ item.USER_CAPTURA_OPERACIONAL?.username }}</span>
      </template>

      <template v-slot:item.USER_CAPTURA_FINANCEIRO="{ item }">
        <span>{{ item.USER_CAPTURA_FINANCEIRO?.username }}</span>
      </template>
      <template v-slot:item.USER_CAPTURA_CONTABIL="{ item }">
        <span>{{ item.USER_CAPTURA_CONTABIL?.username }}</span>
      </template>

      <template v-slot:item.USER_CAPTURA_FINAL="{ item }">
        <span>{{ item.USER_CAPTURA_FINAL?.username }}</span>
      </template>

      <template v-slot:item.daysToEvent="{ item }">
        <span>{{ daysToEvent(item[dateField]) }}</span>
      </template>
      <template v-slot:item.CAMBIO_ABERTO="{ item }">
        <span>{{ item.CAMBIO_ABERTO ? "Sim" : "Não" }}</span>
      </template>
      <template v-slot:item.PRI_VLD_TIPO="{ item }">
        <span>{{ getTipoProcesso(item.PRI_VLD_TIPO) }}</span>
      </template>

      <template v-slot:item.PRI_ESP_REFCLIENTE="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              @click="
                openLink(
                  ` https://saintemarie.conexos.cloud/arq020#/?telaOrigin=TelaProcesso&codeView=imp021&filCod=${item?.FIL_COD}&priCod=${item?.PRI_COD}`
                )
              "
            >
              {{ item.PRI_ESP_REFCLIENTE }}
            </button>

            <!-- <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              color="white"
              @click="openDialog(item)"
              >mdi-select</v-icon
            > -->
          </template>
          <span>Clique para acessar GED </span>
        </v-tooltip>
      </template>

      <!-- Action -->
      <template v-slot:item.action="{ item }">
        <div class="mx-2">
          <div style="display: flex">
            <!-- User logs -->
            <div style="background-color: grey" class="ma-1 pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogUserLogs(item)"
                    >mdi-clipboard-account-outline</v-icon
                  >
                </template>
                <span>Usuários responsáveis</span>
              </v-tooltip>
            </div>

            <!-- Capture -->
            <div
              v-if="!item[userField]?.username"
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialog(item)"
                    >mdi-select</v-icon
                  >
                </template>
                <span>Capturar </span>
              </v-tooltip>
            </div>

            <!-- Finish -->
            <div
              v-if="
                item[userField]?.username &&
                !(item[dateFinish()] ? true : false)
              "
              style="background-color: green"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogFinish(item)"
                    >mdi-check</v-icon
                  >
                </template>
                <span>Finalizar</span>
              </v-tooltip>
            </div>

            <!-- Obs -->
            <div style="background-color: gray" class="ma-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogObs(item)"
                    >mdi-information-box</v-icon
                  >
                </template>
                <span>Observações</span>
              </v-tooltip>
            </div>

            <!-- Email Sent -->
            <div
              v-if="item.EMAIL_SENT && showEmailButton(item)"
              style="color: gray"
              class="ma-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on" color="green" @click=""
                    >mdi-email-check-outline</v-icon
                  >
                </template>
                <span>Email enviado</span>
              </v-tooltip>
            </div>

            <!-- Email Sent -->
            <div v-if="!item.EMAIL_SENT && showEmailButton(item)" class="ma-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    @click="openDialogEmail(item)"
                    >mdi-email-arrow-right-outline</v-icon
                  >
                </template>
                <span>Envio de email pendente</span>
              </v-tooltip>
            </div>

            <div
              style="background-color: green; padding: 4px; color: white"
              v-if="item[`${dateFinish()}`]"
            >
              Finalizado
            </div>
          </div>
        </div>
      </template>

      <!-- Edit -->
      <template v-slot:item.nota="{ item }">
        <div>
          <div style="display: flex">
            <span
              v-if="item.TIPO_NOTA_FISCAL"
              class="mt-2"
              v-money-format="item.VL_NOTA_FISCAL"
            ></span>
            <span v-if="item.TIPO_NOTA_FISCAL" class="ml-2 mt-2">{{
              item.TIPO_NOTA_FISCAL
            }}</span>

            <span v-if="!item.TIPO_NOTA_FISCAL">SEM NOTA</span>
            <div
              v-if="slug == 'faturamento' || slug == 'contabil'"
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEdit(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <!-- Debito -->
      <template v-slot:item.nota_debito="{ item }">
        <div>
          <div style="display: flex">
            <span class="mt-2" v-money-format="item.VL_NOTA_DEBITO"></span>

            <div
              v-if="
                (slug == 'operacional' || slug == 'contabil') &&
                item[userField]?.username &&
                !item[`${dateFinish()}`]
              "
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEditDebito(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.nota_complementar="{ item }">
        <div>
          <div style="display: flex">
            <span
              class="mt-2"
              v-money-format="item.VL_NOTA_COMPLEMENTAR"
            ></span>

            <div
              v-if="
                (slug == 'operacional' || slug == 'contabil') &&
                item[userField]?.username &&
                !item[`${dateFinish()}`]
              "
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEditComplementar(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>
      <!-- Report -->
      <template v-slot:item.expenses="{ item }">
        <div
          v-if="item[dateCaptureField] || slug == 'fechados'"
          style="display: flex"
        >
          <div style="background-color: grey" class="ma-1 pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                  @click="openDialogReport(item)"
                  >mdi-file-document-outline</v-icon
                >
              </template>
              <span>Relatório de despesas</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import DateInput from "../../components/fields/DateInput";
let dateStart = "2024-01-01";
import VCurrencyField from "../../components/VCurrencyField.vue";
import TabelaProcessosTotais from "./TabelaProcessosTotais.vue";
import { isNull } from "lodash";

export default {
  name: "TabelaProcessosFechamento",
  components: { DateInput, VCurrencyField, TabelaProcessosTotais },

  props: [
    "enterprise",
    "filtersParent",
    "sort",
    "sortBy",
    "sortDesc",
    "dateField",
    "dateFields",
    "dateCaptureField",
    "fieldLabel",
    "userField",
    "lastRefresh",
    "filterStatus",
    "title",
    "userId",
    "slug",
    "tabs",
    "tab",
  ],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    lastRefresh(val) {
      this.doSearch();
    },
    showDialog(val) {
      this.message = "";
    },
    showDialogEdit(val) {
      this.message = "";
    },
  },
  data() {
    return {
      menu: false,
      showDialog: false,
      showDialogEdit: false,
      showDialogReport: false,
      showDialogFinish: false,
      showDialogObs: false,
      showDialogEmail: false,
      showDialogUserLogs: false,
      expenses: [],
      itemSelected: {},
      lastRefreshExpenses: 0,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      items: [],
      message: "",
      tipoNota: "DEBITO", //"COMPLEMENTAR"
      NF_EMITIDA: false,
      userLogs: [
        {
          label: "Operacional",
          userField: "USER_CAPTURA_OPERACIONAL",
          dateCaptureField: "DTA_CAPTURA_OPERACIONAL",
          dateCloseField: "DTA_FIM_OPERACIONAL",
        },
        {
          label: "Contábil",
          userField: "USER_CAPTURA_CONTABIL",
          dateCaptureField: "DTA_CAPTURA_CONTABIL",
          dateCloseField: "DTA_FIM_CONTABIL",
        },
        {
          label: "Faturamento",
          userField: "USER_CAPTURA_FINAL",
          dateCaptureField: "DTA_CAPTURA_FINAL",
          dateCloseField: "DTA_FIM_FATURAMENTO",
        },
        {
          label: "Financeiro",
          userField: "USER_CAPTURA_FINANCEIRO",
          dateCaptureField: "DTA_CAPTURA_FINANCEIRO",
          dateCloseField: "DTA_FIM_FINANCEIRO",
        },
      ],
      filtersActive: {},
      headers: [
        {
          text: "Filial",
          align: "start",
          sortable: false,
          value: "FIL_COD",
        },

        {
          text: "Ref. Externa",
          align: "start",
          sortable: false,
          value: "PRI_ESP_REFCLIENTE",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "PRI_VLD_TIPO",
        },
        {
          text: "Encomendante",
          align: "start",
          sortable: false,
          value: "DPE_NOM_PESSOA_LIG",
        },

        {
          text: "Câmbio aberto",
          align: "start",
          sortable: false,
          value: "CAMBIO_ABERTO",
        },
        // {
        //   text: "Dt. Fech. Operacional",
        //   align: "start",
        //   sortable: false,
        //   value: "DTA_FECH_OPERACIONAL",
        // },
      ],
    };
  },

  async mounted() {
    if (this.dateField) {
      if (
        this.slug !== "contabil" &&
        this.slug !== "faturamento" &&
        this.slug !== "fechados"
      )
        this.headers.push({
          text: this.fieldLabel,
          align: "start",
          sortable: false,
          value: this.dateField,
        });

      if (this.slug !== "fechados")
        this.headers.push({
          text: "Qtd dias aberto",
          align: "start",
          sortable: false,
          value: "daysToEvent",
        });
    }

    if (this.dateCaptureField) {
      this.headers.push({
        text: "Ações",
        align: "start",
        sortable: false,
        value: "action",
      });
    }
    // alert(this.userField);
    if (this.userField) {
      this.headers.push({
        text: "Responsável",
        align: "start",
        sortable: false,
        value: this.userField,
      });
    }

    if (this.slug == "contabil") {
      this.headers.push({
        text: "Dt. Faturamento",
        align: "start",
        sortable: false,
        value: "DTA_EMISS_1SAIDA",
      });

      this.headers.push({
        text: "Dt. Finalização operacional",
        align: "start",
        sortable: false,
        value: "DTA_FIM_OPERACIONAL",
      });
    }

    if (this.slug == "faturamento") {
      this.headers.push({
        text: "Dt. Finalização contábil",
        align: "start",
        sortable: false,
        value: "DTA_FIM_CONTABIL",
      });
    }

    if (this.slug == "fechados") {
      this.headers.push({
        text: "Dt. Fech. Operacional",
        align: "start",
        sortable: false,
        value: "DTA_FECH_OPERACIONAL",
      });

      this.headers.push({
        text: "Dt. Finalização contábil",
        align: "start",
        sortable: false,
        value: "DTA_FECH_CONTABIL",
      });

      this.headers.push({
        text: "Dt. Fech. Financeiro",
        align: "start",
        sortable: false,
        value: "DTA_FECH_FINANCEIRO",
      });
    }

    if (
      this.slug == "contabil" ||
      this.slug == "operacional" ||
      this.slug == "financeiro" ||
      //this.slug == "faturamento" ||
      this.slug == "fechados"
    ) {
      this.headers.push({
        text: "Diferença",
        align: "start",
        sortable: false,
        value: "nota_debito",
      });
    }

    if (
      this.slug == "operacional" ||
      this.slug == "financeiro" ||
      this.slug == "faturamento" ||
      this.slug == "fechados" ||
      this.slug == "contabil"
    ) {
      this.headers.push({
        text: "Nota complementar",
        align: "start",
        sortable: false,
        value: "nota_complementar",
      });
    }

    //if (this.slug !== "operacional") {
    this.headers.push({
      text: "Despesas",
      align: "start",
      sortable: false,
      value: "expenses",
    });
    //}

    await this.doSearch();
  },
  methods: {
    showEmailButton(item) {
      return (
        this.slug == "operacional" &&
        ((!item.VL_NOTA_COMPLEMENTAR &&
          (item.DTA_FIM_CONTABIL || item.DTA_FECH_CONTABIL)) ||
          (item.VL_NOTA_COMPLEMENTAR > 0 && item.DTA_FIM_FATURAMENTO))
      );
    },
    getTipoProcesso(PRI_VLD_TIPO) {
      if (PRI_VLD_TIPO == 1) {
        return "CONTA PRÓPRIA";
      }
      if (PRI_VLD_TIPO == 2) {
        return "CONTA ORDDEM DE TERCEIROS";
      }
      if (PRI_VLD_TIPO == 3) {
        return "ENCOMENDA";
      }
      return "";
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    openDialog(item) {
      this.itemSelected = item;

      this.showDialog = true;
    },

    openDialogObs(item) {
      this.itemSelected = item;

      this.showDialogObs = true;
    },

    openDialogEdit(item) {
      this.itemSelected = item;

      this.showDialogEdit = true;
    },

    openDialogEditDebito(item) {
      this.itemSelected = item;
      this.tipoNota = "DEBITO";
      this.showDialogEdit = true;
    },

    openDialogEditComplementar(item) {
      this.itemSelected = item;
      this.tipoNota = "COMPLEMENTAR";
      this.showDialogEdit = true;
    },

    openDialogFinish(item) {
      this.itemSelected = item;

      this.showDialogFinish = true;
    },

    openDialogEmail(item) {
      this.itemSelected = item;

      this.showDialogEmail = true;
    },

    async openDialogReport(item) {
      this.itemSelected = item;
      this.showDialogReport = true;
      let d = new Date();
      this.lastRefreshExpenses = d.getTime();
    },

    async openDialogUserLogs(item) {
      this.itemSelected = item;
      this.showDialogUserLogs = true;
    },

    async doEmailSent(item) {
      let d = new Date();
      let processToUpdate = { id: item.id };
      processToUpdate.DTA_EMAIL_SENT = d.toISOString();
      processToUpdate.USER_EMAIL_SENT = this.user?.id;
      processToUpdate.EMAIL_SENT = true;

      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialogEmail = false;
      this.doSearch();
    },

    async doCapture(item) {
      let d = new Date();
      let processToUpdate = { ...item };
      processToUpdate[this.dateCaptureField] = d.toISOString();
      processToUpdate[this.userField] = this.user?.id;
      if (this.slug == "contabil") {
        processToUpdate.VL_NOTA_FISCAL = this.itemSelected.VL_NOTA_FISCAL;
        if (this.itemSelected.TIPO_NOTA_FISCAL)
          processToUpdate.TIPO_NOTA_FISCAL = this.itemSelected.TIPO_NOTA_FISCAL;
      }
      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialog = false;
      this.doSearch();
    },

    dateFinish() {
      let index = this.dateFields.findIndex((el) => el == this.dateField);
      let dateFieldFinish = this.dateFields[index + 1];
      return dateFieldFinish;
    },

    async doFinish(item) {
      let d = new Date();
      //let processToUpdate = { ...item };
      let processToUpdate = { id: item.id };

      let index = this.dateFields.findIndex((el) => el == this.dateField);
      let dateFieldFinish = this.dateFields[index + 1];
      if (dateFieldFinish) processToUpdate[dateFieldFinish] = d.toISOString();

      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialogFinish = false;
      this.doSearch();
    },

    async doEdit(item) {
      if (
        this.slug == "faturamento" &&
        (!item.VL_NOTA_FISCAL || !item.TIPO_NOTA_FISCAL)
      ) {
        this.message = "Preencher valor da nota e tipo de nota fiscal";

        return;
      }

      let d = new Date();
      let processToUpdate = { ...item };
      processToUpdate[this.dateCaptureField] = d.toISOString();
      processToUpdate[this.userField] = this.user?.id;

      if (this.slug == "faturamento") {
        processToUpdate.VL_NOTA_FISCAL = item.VL_NOTA_FISCAL;
        processToUpdate.TIPO_NOTA_FISCAL = item.TIPO_NOTA_FISCAL;
      }

      if (item?.NF_EMITIDA) {
        processToUpdate.DTA_FECH_FINAL = d.toISOString();
      }
      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialogEdit = false;
      this.showDialogObs = false;
      this.doSearch();
      //refresh search
    },

    async doEditRaw(item) {
      let d = new Date();
      let processToUpdate = { ...item };

      let ans = await strapiApi.processes.put(processToUpdate);

      this.showDialogEdit = false;
      this.showDialogObs = false;
      this.doSearch();
      //refresh search
    },

    daysToEvent(dateEventString) {
      let dateEvent = new Date(dateEventString);
      let today = new Date();

      let diff =
        (today.getTime() - dateEvent.getTime()) / (24 * 60 * 60 * 1000);
      return Math.floor(diff);
      //return 12;
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      this.loading = true;
      let filters = { ...this.filtersParent };
      let filterToAdd;

      if (!filters.and) filters.and = [];

      switch (this.slug) {
        case "fechados":
          filters.DTA_FECH_FINANCEIRO = { gt: dateStart };
          filters.DTA_FECH_CONTABIL = { gt: dateStart };
          filters.DTA_FECH_OPERACIONAL = { gt: dateStart };
          filters.EMAIL_SENT = { eq: true };
          // Code to execute if expression matches value1
          break;
        case "faturamento":
          //filters.or = [];
          filters.and.push({ VL_NOTA_COMPLEMENTAR: { gt: 0 } });
          filters.and.push({
            or: [
              { DTA_FECH_CONTABIL: { gt: dateStart } },
              { DTA_FIM_CONTABIL: { gt: dateStart } },
            ],
          });

          filters.and.push({ DTA_FIM_FATURAMENTO: { null: true } });
          //  filterToAdd = {
          //   and: [
          //     { VL_NOTA_COMPLEMENTAR: { gt: 0 } },
          //     {
          //       or: [
          //         { DTA_FECH_CONTABIL: { gt: dateStart } },
          //         { DTA_FIM_CONTABIL: { gt: dateStart } },
          //       ],
          //     },
          //   ],
          // };

          //filters = { ...filters, ...filterToAdd };

          // Code to execute if expression matches value2
          break;
        case "financeiro":
          filters.and.push({
            or: [
              {
                and: [
                  // { VL_NOTA_COMPLEMENTAR: { eq: 0 } },
                  {
                    or: [
                      { VL_NOTA_COMPLEMENTAR: { eq: 0 } },
                      { VL_NOTA_COMPLEMENTAR: { null: true } },
                    ],
                  },
                  {
                    or: [
                      { DTA_FECH_CONTABIL: { gt: dateStart } },
                      { DTA_FIM_CONTABIL: { gt: dateStart } },
                    ],
                  },
                ],
              },

              {
                and: [
                  { VL_NOTA_COMPLEMENTAR: { gt: 0 } },
                  {
                    or: [{ DTA_FIM_FATURAMENTO: { gt: dateStart } }],
                  },
                ],
              },
            ],
          });

          filters.and.push({ EMAIL_SENT: { eq: true } });
          filters.and.push({ DTA_FECH_FINANCEIRO: { null: true } });

          break;

        case "contabil":
          let filterOr = { or: [] };
          //filters.or = [];

          let filterToAdd = { and: [] };
          filterToAdd.and.push({ DTA_FIM_CONTABIL: { null: true } });

          filterToAdd.and.push({
            or: [
              { DTA_FIM_OPERACIONAL: { gt: dateStart } },
              { DTA_FECH_OPERACIONAL: { gt: dateStart } },
            ],
          });

          filterOr.or.push(filterToAdd);

          let filterToAdd2 = { and: [] };
          filterToAdd2.and.push({ DTA_FIM_CONTABIL: { gt: dateStart } });
          filterToAdd2.and.push({ DTA_FIM_OPERACIONAL: { gt: dateStart } });
          filterToAdd2.and.push({ DTA_FIM_FINANCEIRO: { gt: dateStart } });
          filterToAdd2.and.push({ EMAIL_SENT: { eq: true } });
          filterToAdd2.and.push({ DTA_FECH_OPERACIONAL: { gt: dateStart } });
          filterToAdd2.and.push({ DTA_FECH_FINANCEIRO: { gt: dateStart } });

          filterOr.or.push(filterToAdd2);

          filters.and.push(filterOr);

          break;
        default:
          filters.or = [];
          if (this.tab?.dateField) {
            let filterToAdd = {};
            filterToAdd[this.tab?.dateField] = { gt: dateStart };
            //filterToAdd[this.tab?.dateField] = { null: false };
            filters.or.push(filterToAdd);
          }

          if (this.tab?.dateFieldAux) {
            let filterToAdd = {};
            filterToAdd[this.tab?.dateFieldAux] = { gt: dateStart };
            //filterToAdd[this.tab?.dateFieldAux] = { null: false };

            filters.or.push(filterToAdd);
          }

          if (this.tab?.dateEnd) {
            filters[this.tab?.dateEnd] = { null: true };
          }
      }

      // if (this.tab?.slug == "fechados") {
      //   filters.DTA_FECH_FINANCEIRO = { gt: dateStart };
      //   filters.DTA_FECH_CONTABIL = { gt: dateStart };
      //   filters.DTA_FECH_OPERACIONAL = { gt: dateStart };
      //   filters.EMAIL_SENT = { eq: true };
      // } else {
      //   filters.or = [];
      //   if (this.tab?.dateField) {
      //     let filterToAdd = {};
      //     filterToAdd[this.tab?.dateField] = { gt: dateStart };
      //     //filterToAdd[this.tab?.dateField] = { null: false };
      //     filters.or.push(filterToAdd);
      //   }

      //   if (this.tab?.dateFieldAux) {
      //     let filterToAdd = {};
      //     filterToAdd[this.tab?.dateFieldAux] = { gt: dateStart };
      //     //filterToAdd[this.tab?.dateFieldAux] = { null: false };

      //     filters.or.push(filterToAdd);
      //   }

      //   if (this.tab?.dateEnd) {
      //     filters[this.tab?.dateEnd] = { null: true };
      //   }

      //   //   if (this.tab.slug == "operacional") {
      //   //     if (!filters.and) filters.and = [];

      //   //     if (this.tab?.dateEnd) {
      //   //       let filterToAdd = {};
      //   //       filterToAdd[this.tab?.dateEnd] = { null: true };
      //   //       filters.and.push(filterToAdd);
      //   //     }
      //   //   } else {
      //   //     if (this.tab?.dateEnd) {
      //   //       filters[this.tab?.dateEnd] = { null: true };
      //   //     }
      //   //   }
      // }

      if (this.filterStatus && this.userField) {
        if (this.filterStatus == 1) {
          filters[this.userField] = {
            id: { null: true },
          };
        }
        if (this.filterStatus == 2) {
          if (this.userId) {
            filters[this.userField] = {
              id: { eq: this.userId },
            };
          } else {
            filters[this.userField] = {
              id: { null: false },
            };
          }
        }
        if (this.filterStatus == 3) {
          if (this.userId) {
            filters[this.userField] = {
              id: { eq: this.userId },
            };
            filters[this.tab?.dateClose] = { gt: dateStart };
          } else {
            filters[this.tab?.dateClose] = { gt: dateStart };
          }
        }

        if (this.filterStatus == 4) {
          if (!filters.and) filters.and = [];
          let subfilters = { or: [] };

          let filterToAdd = {};
          filterToAdd.and = [];
          filterToAdd.and.push({
            or: [
              { VL_NOTA_COMPLEMENTAR: { eq: 0 } },
              { VL_NOTA_COMPLEMENTAR: { null: true } },
            ],
          });
          filterToAdd.and.push({
            or: [
              { DTA_FIM_CONTABIL: { gt: dateStart } },
              { DTA_FECH_CONTABIL: { gt: dateStart } },
            ],
          });
          filterToAdd.and.push({
            or: [{ EMAIL_SENT: { eq: false } }, { EMAIL_SENT: { null: true } }],
          });

          subfilters.or.push(filterToAdd);

          let filterToAdd2 = {};
          filterToAdd2.and = [];
          filterToAdd2.and.push({ VL_NOTA_COMPLEMENTAR: { gt: 0 } });
          filterToAdd2.and.push({
            or: [{ DTA_FIM_FATURAMENTO: { gt: dateStart } }],
          });

          filterToAdd2.and.push({
            or: [{ EMAIL_SENT: { eq: false } }, { EMAIL_SENT: { null: true } }],
          });

          subfilters.or.push(filterToAdd2);

          filters.and.push(subfilters);
        }
      }

      if (!this.filterStatus) {
        if (this.userId) {
          //   id: { eq: this.userId },
          // };
          // filters.or.push({ ...filterToAdd });

          // filterToAdd[this.userField] = {
          //   id: { null: true },
          // };
          // filters.or.push({ ...filterToAdd });

          filters[this.userField] = {
            id: { eq: this.userId },
          };
        }
      }
      // alert("1");
      // alert(JSON.stringify({ filters }));
      // if(this.userId && )

      this.filtersActive = filters;

      let { values, pagination } =
        await strapiApi.processes.getProcessosFechamento(
          this.page,
          this.itemsPerPage,
          // this.sortBy,
          // this.sortDesc,
          this.dateField,
          // "id",
          false,
          filters
        );

      this.items = values;

      this.serverItemsLength = pagination?.total;

      this.loading = false;
    },
  },
};
</script>

<style>
.active-row {
  background-color: #f0f8ff; /* You can change this color */
}
</style>
