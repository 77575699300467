import functions from "./functions";
var vwFechProcessosTotais = {
  async getWithPagination(page, pageSize, sort, sortDesc, filters) {
    var query = `query vwFechProcessosTotais($filters:VwFechProcessosTotalFiltersInput!){
      vwFechProcessosTotais( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            PRI_COD
            FIL_COD
            DESCRICAO
            VL_DI
            VL_DESP_PROCESSO
            VL_DESP_NF_SERV
            VL_NF_ENTRADA
            VL_NF_SAIDA
            VL_LANC_CRED
            VL_LANC_DEB
            VL_ADIANTAMENTOS
            VL_DIF_FORN_PROC
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination vwFechProcessosTotais time: ${
          (end - start) / 1000
        } s`
      );

    const pagination = res?.data?.vwFechProcessosTotais?.meta?.pagination;
    const values = functions.sanitize(res.data.vwFechProcessosTotais.data);
    return { values, pagination };
  },
};

export default vwFechProcessosTotais;
