import functions from "./functions";

//roles
//1-Admin (authenticated)
//2-Public
//3-Client
//4Trader
//5-Operations

var users = {
  async getById(userId) {
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users/${userId}`;
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[id]=${userId}&populate=team,businessUnits,profile,role,enterprises,notifications,processes`;

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    if (res && res.length > 0) return res[0];
    else return null;
  },

  async getWitFilterString(filter) {
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users/${userId}`;
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/users?${filter}`;

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    return res;
  },

  async login(identifier, password) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/auth/local`;
    let raw = {
      identifier,
      password,
    };
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        //   Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.jwt) {
      window.strapiToken = res.jwt;
      return res.user;
    } else {
      return res;
    }
  },

  async changePassword(currentPassword, password, passwordConfirmation) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/auth/change-password`;
    console.log(`token:${window.strapiToken}`);
    let raw = {
      currentPassword,
      password,
      passwordConfirmation,
    };

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async register(username, email, password, passwordConfirmation) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/auth/local/register`;
    let raw = {
      username,
      email,
      password,
      passwordConfirmation,
    };
    console.log(JSON.stringify({ url }));
    console.log(JSON.stringify({ raw }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async update(user) {
    const {
      id,
      username,
      emailNotificationActive,
      whatsappNotificationActive,
      phoneNumber,
    } = user;

    if (!id || !username) {
      return {
        data: null,
        error: {
          status: 500,
          name: "InternalServerError",
          message: "Erro ao realizar a atualização",
        },
      };
    }

    let url = `${process.env.VUE_APP_STRAPI_URL}/api/users/${id}`;

    let raw = {
      username,
      emailNotificationActive,
      whatsappNotificationActive,
      phoneNumber: phoneNumber.replace(/\D/g, ""),
    };

    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async updateNotifications(id, notifications) {
    if (!id || !notifications) {
      return {
        data: null,
        error: {
          status: 500,
          name: "InternalServerError",
          message: "Erro ao realizar a atualização",
        },
      };
    }

    let url = `${process.env.VUE_APP_STRAPI_URL}/api/users/${id}`;

    let raw = {
      notifications,
    };

    console.log(JSON.stringify({ url, raw }));

    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async resetPassword(code, password, passwordConfirmation) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/auth/reset-password`;
    let raw = {
      code,
      password,
      passwordConfirmation,
    };
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async forgotPassword(email) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/auth/forgot-password`;
    let raw = {
      email,
    };
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async post(user, roleId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/users`;

    user.role = {
      connect: [{ id: roleId }],
    };
    let raw = user;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${window.strapiToken}`,
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default users;
