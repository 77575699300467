import { render, staticRenderFns } from "./Fechamento.vue?vue&type=template&id=715684f0&scoped=true&"
import script from "./Fechamento.vue?vue&type=script&lang=js&"
export * from "./Fechamento.vue?vue&type=script&lang=js&"
import style0 from "./Fechamento.vue?vue&type=style&index=0&id=715684f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "715684f0",
  null
  
)

export default component.exports