<template>
  <v-container fluid class="ma-0 pa-0" style="">
    <v-card
      class="mx-auto mt-0"
      :style="`background-color: ${this.color ?? white}; height: 100%`"
    >
      <v-card-text>
        <v-row align="center">
          <!-- <v-col cols="4" xl="2" lg="3">
            <div :style="iconStyle">
              <v-icon class="text-h1 white--text">{{ icon }}</v-icon>
            </div>
          </v-col> -->
          <v-col cols="8" xl="10" lg="9">
            <h4 style="color: white">{{ title }}</h4>
            <p style="color: white" class="mt-4 text-highlight">
              <span class="mx-2" :style="fontStyle">
                {{ value }}
              </span>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "dashboard-value-card",
  props: ["title", "value", "icon", "color"],

  components: {},

  data() {
    return {
      fontStyle: {
        "line-height": 1.2,
        "font-size": "18px",
      },
      iconStyle: {
        width: "75px",
        height: "75px",
        "border-radius": "100%",
        "background-color": this.color ?? "#229dce",
        "justify-content": "center",
        display: "flex",
      },
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  async mounted() {
    this.updateStyle();
    window.addEventListener("resize", this.updateStyle);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateStyle);
  },
  created() {},
  watch: {},
  methods: {
    updateStyle() {
      const ratio = window.devicePixelRatio;

      if (ratio > 1) {
        this.fontStyle = {
          "line-height": 1.2,
          "font-size": `${16 / ratio}px`,
        };

        this.iconStyle = {
          width: `${75 / ratio}px`,
          height: `${75 / ratio}px`,
          "border-radius": "100%",
          "background-color": this.color ?? "#229dce",
          "justify-content": "center",
          display: "flex",
        };
      }
    },
  },
};
</script>

<style scoped></style>
