import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/views/dashboard/pages/dashboard/Dashboard";
import SimpleDashboard from "@/views/dashboard/pages/dashboard/SimpleDashboard";

import Process from "@/views/dashboard/pages/processes/Process";
import Account from "@/views/dashboard/pages/user/Account";

import Construction from "@/views/dashboard/pages/info/Construction";
import Sla from "@/views/dashboard/pages/sla/Sla";
import Alerts from "@/views/dashboard/pages/alerts/Alerts";
import Finance from "@/views/dashboard/pages/finance/Finance";
import Nfses from "@/views/dashboard/pages/nfses/Nfses";
import store from "./store";
import Enterprises from "@/views/dashboard/pages/enterprises/Enterprises";
import EnterprisesDistribution from "@/views/dashboard/pages/enterprises/EnterprisesDistribution";
import Fechamento from "@/views/dashboard/pages/fechamento/Fechamento";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "Login",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Login.vue"
        ),
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Register.vue"
        ),
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",

      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/ResetPassword.vue"
        ),
    },

    {
      path: "/",
      redirect: { path: "/dashboard" },
      component: () => import("@/views/dashboard/Index"),

      children: [
        {
          name: "Dashboard",
          path: "/dashboard",
          component: Dashboard,
        },

        {
          name: "SimpleDashboard",
          path: "/simple-dashboard",
          component: SimpleDashboard,
        },

        {
          name: "Process",
          path: "/process/:priCod",
          props: true,
          component: Process,
        },

        {
          name: "Process",
          path: "/process-balance/:priCod",
          props: true,
          component: Process,
        },
        {
          path: "/account",
          name: "Account",
          component: Account,
        },

        {
          path: "/shippings",
          name: "Construction",
          component: Construction,
        },

        {
          path: "/not-authorized",
          name: "NotAuthorized",
          component: Construction,
        },
        {
          path: "/finance",
          name: "Finance",
          component: Finance,
        },
        {
          path: "/nfses",
          name: "Nfses",
          component: Nfses,
        },
        {
          path: "/enterprises",
          name: "Enterprises",
          component: Enterprises,
        },
        {
          path: "/enterprises-distribution",
          name: "Enterprises Distribution",
          component: EnterprisesDistribution,
        },
        //EnterprisesDistribution

        {
          path: "/sla",
          name: "Sla",
          component: Sla,
        },

        {
          path: "/alerts",
          name: "Alerts",
          component: Alerts,
        },
        {
          path: "/fechamento",
          name: "Fechamento",
          component: Fechamento,
        },

        {
          name: "/#",
          path: "/:pathMatch(.*)*",
          //component: () => import("./views/dashboard/pages/NotFound"),
          component: Dashboard,
        },
      ],
    },
  ],
});
