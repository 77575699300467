<template>
  <!-- :style="myStyle" -->

  <VTextField
    ref="input"
    v-currency
    :label="label"
    :disabled="disabled"
    :suffix="suffix"
    :prefix="prefix"
    :value="formattedValue"
    @change="onChange"
    @blur="_blur"
    @input="onInput"
    :prepend-icon="prependIcon"
    @click:prepend="clickPrepend"
    :append-outer-icon="appendOuterIcon"
    @click:append-outer="clickAppendOuter"
  />
</template>

<script>
export default {
  name: "VCurrencyField",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    //    myStyle:{
    //   type: String,
    //   default: null
    // },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    _blur() {
      this.$emit("blur");
    },

    clickPrepend() {
      this.$emit("click-prepend");
    },

    clickAppendOuter() {
      this.$emit("click-append-outer");
    },

    setValue(value) {
      try {
        this.$ci.setValue(this.$refs.input, value);
      } catch (error) {
        console.error(error);
      }
    },
    onInput(value) {
      try {
        this.$emit("input", this.$ci.getValue(this.$refs.input));
        this.formattedValue = value;
      } catch (error) {
        console.error(error);
      }
    },
    onChange(value) {
      try {
        this.$emit("change", this.$ci.getValue(this.$refs.input));
        this.formattedValue = value;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
