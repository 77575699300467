<template>
  <v-app id="inspire">
    <v-container fluid>
      <br />
      <h1>Fechamento de processos</h1>

      <v-container fluid class="">
        <v-row class="pa-2">
          <!-- <v-col cols="12" lg="3" md="5" sm="5" class="pa-0 ma-0">

          </v-col> -->

          <v-container fluid style="">
            <v-row>
              <v-col v-for="(tab, index) in tabs" :key="index">
                <CardValue
                  :title="tab.title"
                  :color="tab.color"
                  :value="tab.summary"
                ></CardValue>
              </v-col>
            </v-row>
          </v-container>
        </v-row>

        <v-row>
          <v-select
            class="mx-5"
            label="Filial"
            :items="[
              { value: 0, text: 'Todas' },
              { value: 1, text: 1 },
              { value: 2, text: 2 },
              { value: 3, text: 3 },
              { value: 4, text: 4 },
              { value: 6, text: 6 },
              { value: 7, text: 7 },
              { value: 8, text: 8 },
              { value: 9, text: 9 },
              { value: 10, text: 10 },
              { value: 12, text: 12 },
              { value: 13, text: 13 },
              { value: 14, text: 14 },
              { value: 16, text: 16 },
              { value: 17, text: 17 },
              { value: 18, text: 18 },
              { value: 20, text: 20 },
              { value: 21, text: 21 },
            ]"
            v-model="filters.FIL_COD"
            item-text="text"
            item-value="value"
            style="
              color: black;
              font-size: 16px !important;
              font-weight: 600 !important;
            "
          >
          </v-select>

          <v-text-field
            class="mx-5"
            label="Referência do processo"
            v-model="filters.PRI_ESP_REFCLIENTE"
          >
          </v-text-field>

          <DateInput v-model="filters.dateStart" label="Período de"></DateInput>
          <DateInput v-model="filters.dateEnd" label="Período Até"></DateInput>

          <v-select
            class="mx-5"
            label="Status"
            :items="[
              { value: 0, text: 'TODOS' },
              { value: 1, text: 'PENDENTES' },
              { value: 2, text: 'CAPTURADOS' },
              { value: 3, text: 'FINALIZADOS' },
              { value: 4, text: 'EMAIL PENDENTE' },
            ]"
            v-model="filters.status"
            item-text="text"
            item-value="value"
            style="
              color: black;
              font-size: 16px !important;
              font-weight: 600 !important;
            "
          >
          </v-select>

          <v-select
            class="mx-5"
            label="Câmbio aberto"
            :items="[
              { value: 0, text: 'TODOS' },
              { value: 1, text: 'SIM' },
              { value: 2, text: 'NÃO' },
            ]"
            v-model="filters.cambio"
            item-text="text"
            item-value="value"
            style="
              color: black;
              font-size: 16px !important;
              font-weight: 600 !important;
            "
          >
          </v-select>

          <v-select
            class="mx-5"
            label="Responsável"
            :items="[
              { value: 0, text: 'TODOS' },
              ...users.map((el) => ({
                value: el.id,
                text: el.username,
              })),
            ]"
            v-model="filters.userId"
            item-text="text"
            item-value="value"
            style="
              color: black;
              font-size: 16px !important;
              font-weight: 600 !important;
            "
          >
          </v-select>
          <div class="mt-4">
            <AutocompleteSelect
              style=""
              label="Encomendante"
              :items="optionsEncomendantes"
              v-model="chipsEncomendantes"
              v-if="!(user && user.cmn025 && user.profileId == 3)"
            ></AutocompleteSelect>
          </div>
          <v-btn class="primary" @click="refresh" :disabled="loading"
            >Buscar
            <!-- <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="white"
                    ></v-progress-circular> -->
          </v-btn>
        </v-row>
      </v-container>
      <br />
      <!-- <tr v-for="(item, index) in itemsSearch" v-bind:key="item.id"> -->
      <v-tabs
        style="min-height: 600px"
        color="primary accent-4"
        active-class="white black--text"
      >
        <v-tab
          v-for="(tab, index) in tabs.filter((el) => el.show)"
          :key="index"
          :style="`background-color:${tab.color}`"
          class="white--text"
          ><b>{{ tab.title }}</b></v-tab
        >

        <!-- Empresas -->
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <TabelaProcessosFechamento
            :date-field="tab.dateField"
            :date-fields="tabs.map((el) => el.dateField)"
            :dateCaptureField="tab.dateCaptureField"
            :fieldLabel="tab.fieldLabel"
            :user-field="tab.userField"
            :lastRefresh="tab.lastRefresh"
            :filters-parent="formatFilters()"
            :filter-status="filters.status"
            :title="tab.title"
            :user-id="filters.userId"
            :slug="tab.slug"
            :tabs="tabs"
            :tab="tab"
          ></TabelaProcessosFechamento>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </v-app>
</template>

<script>
import TabelaProcessosFechamento from "./TabelaProcessosFechamento.vue";
import { mapState } from "vuex";
import DateInput from "../../components/fields/DateInput";
import { filter } from "lodash";
import strapiApi from "../../../../libraries/strapi/strapiApi";
let dateStart = "2024-01-01";
import CardValue from "./CardValue.vue";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
export default {
  name: "Fechamento",
  components: {
    TabelaProcessosFechamento,
    DateInput,
    CardValue,
    AutocompleteSelect,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    // alert("Load users");
    let users = await strapiApi.users.getWitFilterString(
      "filters[acesso_fech_processos]=true"
    );

    let summary = await strapiApi.processes.getSummaryFechamento();

    this.summary = summary;

    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      summary: summary[tab.slug] ?? 0,
    }));

    this.users = users;

    this.encomendantes = await strapiApi.enterprises.getOptions();

    this.optionsEncomendantes = this.encomendantes;
    //alert(JSON.stringify(users));
  },

  data() {
    return {
      filters: {
        FILIAL: 0,
        dateStart: "",
        dateEnd: "",
        PRI_ESP_REFCLIENTE: "",
        status: 0,
        cambio: 0,
        userId: 0,
      },

      encomendantes: [],
      optionsEncomendantes: [],
      chipsEncomendantes: [],

      summary: {},

      users: [],

      loading: false,
      stages: [
        {
          label: "Operacional",
          dateField: "DTA_FECH_OPERACIONAL",
          dateCaptureField: "DTA_CAPTURA_OPERACIONAL",
          userField: "USER_CAPTURA_OPERACIONAL",
        },
        {
          label: "Financeiro",
          dateField: "DTA_FECH_FINANCEIRO",
          dateCaptureField: "DTA_CAPTURA_FINANCEIRO",
          userField: "USER_CAPTURA_FINANCEIRO",
        },
        {
          label: "Contábil",
          dateField: "DTA_FECH_CONTABIL",
          dateCaptureField: "DTA_CAPTURA_CONTABIL",
          userField: "USER_CAPTURA_CONTABIL",
        },
      ],

      tabs: [
        {
          slug: "operacional",
          title: "OPERACIONAL",
          show: true,
          fieldLabel: "Dt. Faturamento",
          dateField: "DTA_EMISS_1SAIDA",
          color: "#7F7F7F",
          dateCaptureField: "DTA_CAPTURA_OPERACIONAL",
          dateClose: "DTA_FIM_OPERACIONAL",
          dateEnd: "DTA_FECH_OPERACIONAL",
          userField: "USER_CAPTURA_OPERACIONAL",
          lastRefresh: 0,
          summary: 0,
        },

        {
          slug: "contabil",
          title: "CONTÁBIL",
          show: true,
          fieldLabel: "Dt. Fech. Financ.",
          //dateField: "DTA_FECH_FINANCEIRO",
          dateField: "DTA_FIM_OPERACIONAL",
          dateFieldAux: "DTA_FECH_OPERACIONAL",
          color: "#82CAEB",
          dateCaptureField: "DTA_CAPTURA_CONTABIL",

          dateClose: "DTA_FIM_CONTABIL",
          dateEnd: "DTA_FECH_CONTABIL",

          userField: "USER_CAPTURA_CONTABIL",
          lastRefresh: 0,
          summary: 0,
        },

        {
          slug: "faturamento",
          title: "FATURAMENTO",
          show: true,
          fieldLabel: "Dt. Fech. Contáb.",
          dateField: "DTA_FIM_CONTABIL",
          dateFieldAux: "DTA_FECH_CONTABIL",
          color: "#EED202",
          lastRefresh: 0,
          dateCaptureField: "DTA_CAPTURA_FINAL",

          dateClose: "DTA_FIM_FATURAMENTO",
          dateEnd: "DTA_FIM_FATURAMENTO",

          userField: "USER_CAPTURA_FINAL",
          lastRefresh: 0,
          summary: 0,
        },

        {
          slug: "financeiro",
          title: "FINANCEIRO",
          show: true,
          fieldLabel: "Dt. Fim Faturamento",
          // dateField: "DTA_FECH_OPERACIONAL",
          dateField: "DTA_FIM_FATURAMENTO",

          // dateField: "DTA_FIM_CONTABIL",
          // dateFieldAux: "DTA_FECH_CONTABIL",

          //dateFieldAux: "DTA_FECH_FINAL", //ANTIGO CAMPO
          color: "#E97132",
          dateCaptureField: "DTA_CAPTURA_FINANCEIRO",

          dateClose: "DTA_FIM_FINANCEIRO",
          dateEnd: "DTA_FECH_FINANCEIRO",

          userField: "USER_CAPTURA_FINANCEIRO",
          lastRefresh: 0,
          summary: 0,
        },

        {
          slug: "fechados",
          title: "FECHADOS",
          show: true,
          fieldLabel: "Dt. Fech. Financeiro",
          dateField: "DTA_FIM_FINANCEIRO",
          dateFieldAux: "DTA_FECH_FINANCEIRO",
          color: "#3A7D22",
          lastRefresh: 0,
          summary: 0,
        },
      ],
    };
  },
  methods: {
    refresh() {
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].lastRefresh = new Date().getTime();
      }
    },

    formatFilters() {
      // filters: {
      //   FILIAL: 0,
      //   dateStart: "",
      //   dateEnd: "",
      //   PRI_ESP_REFCLIENTE: "",
      // },
      let filtersFormatted = {};

      filtersFormatted.and = [];
      if (this.filters.FIL_COD) {
        filtersFormatted.FIL_COD = { eq: this.filters.FIL_COD };
      }
      if (this.filters.PRI_ESP_REFCLIENTE) {
        filtersFormatted.PRI_ESP_REFCLIENTE = {
          containsi: this.filters.PRI_ESP_REFCLIENTE,
        };
      }

      if (this.filters.dateEnd || this.filters.dateStart) {
        //filtersFormatted.and = [];
        if (this.filters.dateStart) {
          filtersFormatted.and.push({
            DTA_EMISS_1SAIDA: {
              gte: `${this.filters.dateStart}-01`,
            },
          });
        }

        if (this.filters.dateEnd) {
          let dateEnd = new Date(`${this.filters.dateEnd}-01T03:00:00.000Z`);
          dateEnd.setMonth(dateEnd.getMonth() + 1);
          filtersFormatted.and.push({
            DTA_EMISS_1SAIDA: {
              lt: dateEnd.toISOString().substring(0, 10),
            },
          });
        }
      }
      if (this.filters.cambio) {
        if (this.filters.cambio == 1) {
          filtersFormatted.CAMBIO_ABERTO = {
            eq: true,
          };
        }

        if (this.filters.cambio == 2) {
          filtersFormatted.or = [
            {
              CAMBIO_ABERTO: {
                eq: false,
              },
            },
            {
              CAMBIO_ABERTO: {
                null: true,
              },
            },
          ];
        }
      }

      if (this.chipsEncomendantes?.length > 0) {
        if (this.chipsEncomendantes.length == 1) {
          filtersFormatted.and.push({
            DPE_NOM_PESSOA_LIG: { containsi: this.chipsEncomendantes[0] },
          });
        } else {
          filtersFormatted.and.push({
            or: this.chipsEncomendantes.map((el) => ({
              DPE_NOM_PESSOA_LIG: { containsi: el },
            })),
          });
        }
      }

      return filtersFormatted;

      // if (this.filters.dateStart) {
      //   filtersFormatted = new Date(`${this.filters.dateStart}-01T03:00:00.000Z`);
      // }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}

.dashboard-width {
  max-width: 2400px;
}
</style>
